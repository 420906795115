import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Button } from "@progress/kendo-react-buttons";
import { useAuth } from "../../../Core/Context/AuthContext";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { TextBox } from "@progress/kendo-react-inputs";
import { Loader, ErrorFallback } from "smart-react";
import { createAppKey } from "../../Services/ProfileService";
import {
  Card,
  CardTitle,
  CardBody,
  CardActions,
} from "@progress/kendo-react-layout";
import "./ApplicationKey.scss";

const ApplicationKey = () => {
  const { getTokensFromStorage } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [appKey, setAppKey] = React.useState("");

  /**
   * Create Application Key
   */
  const generateAppkey = async () => {
    setLoading(true);
    const appKeyId = await createAppKey();
    setAppKey(appKeyId?.Payload);
    setLoading(false);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(appKey);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <React.Suspense fallback={<Loader />}>
        {loading && <Loader />}
        <Card className="tile-layout-card">
          <CardTitle>
            <div className="page-title page-button-title">Application Key</div>
          </CardTitle>
          <CardBody className="app-key-body">
            <div className="field-wrapper k-d-flex k-align-items-baseline">
              <FloatingLabel
                label={"Application Key"}
                editorId={"ApplicationKey"}
                editorValue={appKey}
              >
                <TextBox
                  id={"ApplicationKey"}
                  name={"ApplicationKey"}
                  value={appKey}
                />
              </FloatingLabel>
              <div className="k-ml-2">
                <Button
                  themeColor={"primary"}
                  className="k-mx-1"
                  icon="copy"
                  onClick={handleCopy}
                ></Button>
              </div>
            </div>
          </CardBody>
          <CardActions>
            <div className={"k-form-buttons button-form-wrapper"}>
              <Button
                themeColor={"primary"}
                type={"submit"}
                onClick={generateAppkey}
              >
                Generate Application Key
              </Button>
            </div>
          </CardActions>
        </Card>
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default ApplicationKey;
